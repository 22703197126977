export enum DataRange {

    Custom = 'Custom',
    FiveYears = 'FiveYears',
    Max = 'Max',
    OneDay = 'OneDay',
    OneMonth = 'OneMonth',
    OneWeek = 'OneWeek',
    OneYear = 'OneYear',
    ThreeDays = 'ThreeDays',
    ThreeMonths = 'ThreeMonths',
    ThreeYears = 'ThreeYears',
    SixMonths = 'SixMonths'

}
