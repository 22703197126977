import {FormControl, MenuItem, Select} from "@mui/material";
import {DateRangePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {ApexOptions} from "apexcharts";
import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import {Link, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {AssetMarketType} from "../../../enums/asset/AssetMarketType";
import {DataRange} from "../../../enums/data/DataRange";
import {DataTimeframe} from "../../../enums/data/DataTimeframe";
import {DataType} from "../../../enums/data/DataType";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {Asset} from "../../../models/asset/Asset";
import {AssetChartRequest} from "../../../models/asset/AssetChartRequest";
import {DataAsset} from "../../../models/data/asset/DataAsset";


export default function AssetChartComponent() {

    const {market, name} = useParams();

    const [asset, changeAsset] = useState<Asset>(Core.initializeAsset());
    const [dateRange, changeDateRange] = useState<Date[]>([]);
    const [range, changeRange] = useState<DataRange>(DataRange.OneYear);
    const [timeframe, changeTimeframe] = useState<DataTimeframe>(DataTimeframe.Daily);
    const [type, changeType] = useState<DataType>(DataType.Candlestick);

    const [chartSeriesCandlestick, changeChartSeriesCandlestick] = useState<any[]>([{
        'name': decodeURIComponent(name!),
        'data': []
    }]);
    let chartOptionsCandlestick: ApexOptions = {
        'chart': {
            'toolbar': {
                'autoSelected': 'selection',
                'show': false,
                'tools': {
                    'pan': true,
                    'selection': true
                }
            },
            'zoom': {
                'enabled': false
            }
        },
        'grid': {
            'show': true,
            'borderColor': '#3c3c3c',
            'strokeDashArray': 5,
            'position': 'back',
            'xaxis': {
                'lines': {
                    'show': true
                }
            },
            'yaxis': {
                'lines': {
                    'show': true
                }
            }
        },
        'plotOptions': {
            'candlestick': {
                'colors': {
                    'upward': '#089981',
                    'downward': '#f23645'
                }
            }
        },
        'tooltip': {
            'theme': 'dark'
        },
        'xaxis': {
            'type': 'datetime',
            'labels': {
                'style': {
                    'colors': '#fff',
                },
            }
        },
        'yaxis': {
            'labels': {
                'style': {
                    'colors': '#fff',
                },
            },
            'opposite': true
        }
    };
    const [chartSeriesBar, changeChartSeriesBar] = useState<any[]>([{
        'name': decodeURIComponent(name!),
        'data': []
    }]);
    let chartOptionsBar: ApexOptions = {
        'chart': {
            'toolbar': {
                'autoSelected': 'selection',
                'show': false,
                'tools': {
                    'pan': true,
                    'selection': true
                }
            },
            'zoom': {
                'enabled': false
            }
        },
        'colors': ['#005da0'],
        'dataLabels': {
            'enabled': false,
        },
        'grid': {
            'show': false
        },
        'tooltip': {
            'enabled': false
        },
        'xaxis': {
            'type': 'datetime',
            'labels': {
                'show': false
            }
        },
        'yaxis': {
            'show': false
        }
    };

    const filterDateRange = (value: any) => {

        let dateRange: Date[] = [];

        value.forEach((item: any) => {

            if(item !== null) {

                dateRange.push(item.$d);

            }

        });

        changeDateRange(dateRange);

    }

    const initializeChart = () => {

        let start: Date = Core.initializeDataAssetDetailRange(range!);
        let end: Date = new Date();

        if(dateRange.length > 0) {

            start = dateRange[0];
            end = dateRange[1];

        }

        fetch(process.env.REACT_APP_API_URL + '/asset/chart/', Rest.initializeRequest(
            new AssetChartRequest(undefined, undefined, end, AssetMarketType[Core.toCapital(market!) as keyof typeof AssetMarketType], decodeURIComponent(name!), start, timeframe, type),
            '/asset/chart/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.asset !== null) {

                    changeAsset(data.asset);

                } else {

                    changeAsset(Core.initializeAsset());

                }

                if(data.data !== null) {

                    chartSeriesCandlestick[0].data = [];
                    let lastDate: Date | undefined = undefined;
                    let lastClose: number | undefined = undefined;

                    data.data.map((dataAsset: DataAsset) => {

                        console.log(Core.toDatetime(dataAsset.date!));
                        chartSeriesCandlestick[0].data.push({
                            'x': Core.toDatetime(dataAsset.date!),
                            'y': [dataAsset.price!.open!.toFixed(2), dataAsset.price!.high!.toFixed(2), dataAsset.price!.low!.toFixed(2), dataAsset.price!.close!.toFixed(2)]
                        });

                        let color: string = 'rgba(8, 153, 129, 0.3)';

                        if(dataAsset.price!.open! > dataAsset.price!.close!) {

                            color = 'rgba(242, 54, 69, 0.3)';

                        }

                        chartSeriesBar[0].data.push({
                            'fillColor': color,
                            'x': Core.toDatetime(dataAsset.date!),
                            'y': dataAsset.volume!.toFixed(2)
                        });

                        lastDate = Core.toDatetime(dataAsset.date!);
                        lastClose = dataAsset.price!.close!;

                    });

                    if(lastDate !== undefined && lastClose !== undefined) {

                        let increment: number = 1

                        data.predictions.forEach(function(prediction: number) {

                            lastDate!.setDate(lastDate!.getDate() + increment);
                            let date: Date = new Date(lastDate!);

                            let high: number = prediction!;

                            if(prediction < lastClose!) {

                                high = lastClose!;

                            }

                            let low: number = lastClose!;

                            if(lastClose! > prediction) {

                                low = prediction;

                            }

                            chartSeriesCandlestick[0].data.push({
                                'x': date,
                                'y': [lastClose!.toFixed(2), high.toFixed(2), low.toFixed(2), prediction.toFixed(2)]
                            });

                            lastClose = prediction;

                        });

                    }

                    console.log(chartSeriesCandlestick[0].data)

                    changeChartSeriesCandlestick(chartSeriesCandlestick);
                    changeChartSeriesBar(chartSeriesBar);
                    //ApexCharts.exec('apexcharts-asset-detail-candlestick', 'updateSeries', chartSeriesCandlestick);

                }

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    useEffect(() => {

        initializeChart();

    }, [dateRange, range, timeframe, type]);

    return (
        <div className="content">
            <div className="chart-boxed">
                <div className="detail-boxed-chart-title">
                    <Link className="back" to={'/assets/' + market + '/' + name + '/'}>
                        <i className="ti ti-arrow-left"></i>
                    </Link>
                    {
                        (asset.name !== undefined && asset.symbol !== undefined) &&
                        <h3>{asset.name + ' (' + asset.symbol + ')'}</h3>
                    }
                    {
                        (asset.name === undefined || asset.symbol === undefined) &&
                        <h3>&nbsp;</h3>
                    }
                    <div className="option">
                        <FormControl fullWidth>
                            <Select value={timeframe} onChange={(event) => changeTimeframe(DataTimeframe[event.target.value as keyof typeof DataTimeframe])}>
                                <MenuItem value={DataTimeframe.FiveMinutes}>5 Minutes</MenuItem>
                                <MenuItem value={DataTimeframe.FifteenMinutes}>15 Minutes</MenuItem>
                                <MenuItem value={DataTimeframe.Hourly}>Hourly</MenuItem>
                                <MenuItem value={DataTimeframe.Daily}>Daily</MenuItem>
                                <MenuItem value={DataTimeframe.Weekly}>Weekly</MenuItem>
                                <MenuItem value={DataTimeframe.Monthly}>Monthly</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="option">
                        <FormControl fullWidth>
                            <Select value={type} onChange={(event) => changeType(DataType[event.target.value as keyof typeof DataType])}>
                                <MenuItem value={DataType.Candlestick}>Candlestick</MenuItem>
                                <MenuItem value={DataType.HeikinAshi}>Heikin Ashi</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="option">
                        <FormControl fullWidth>
                            <Select value={range} onChange={(event) => changeRange(DataRange[event.target.value as keyof typeof DataRange])}>
                                <MenuItem value={DataRange.OneWeek}>1 Week</MenuItem>
                                <MenuItem value={DataRange.OneMonth}>1 Month</MenuItem>
                                <MenuItem value={DataRange.ThreeMonths}>3 Months</MenuItem>
                                <MenuItem value={DataRange.SixMonths}>6 Months</MenuItem>
                                <MenuItem value={DataRange.OneYear}>1 Year</MenuItem>
                                <MenuItem value={DataRange.ThreeYears}>3 Years</MenuItem>
                                <MenuItem value={DataRange.FiveYears}>5 Years</MenuItem>
                                <MenuItem value={DataRange.Max}>Max</MenuItem>
                                <MenuItem value={DataRange.Custom}>Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {
                        range === DataRange.Custom &&
                        <div className="option option-date-range">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateRangePicker localeText={{start: 'Date Start', end: 'Date End'}}
                                                 onChange={(value) => filterDateRange(value)}></DateRangePicker>
                            </LocalizationProvider>
                        </div>
                    }
                </div>
                <Chart options={chartOptionsCandlestick} series={chartSeriesCandlestick} type="candlestick" width="100%" height="450" style={{'zIndex': '1'}}></Chart>
                {/* width = apexcharts-grid-borders + 22 */}
                <Chart options={chartOptionsBar} series={chartSeriesBar} type="bar" width="1196.4" height="120" style={{'margin': '-80px 0 0 0'}}></Chart>
            </div>
        </div>
    );

}
