import {BaseRequest} from "../core/BaseRequest";
import {Vpn} from "./Vpn";


export class VpnSaveRequest extends BaseRequest {

    public authentication: string | undefined;

    public data: Vpn | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, data: Vpn | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.data = data;

    }

}
