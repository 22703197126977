import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {TableFilterType} from "../../../enums/core/table/TableFilterType";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {DataRequest} from "../../../models/core/DataRequest";
import {Table} from "../../../models/core/table/Table";
import {TableFilter} from "../../../models/core/table/TableFilter";
import {TableRequest} from "../../../models/core/table/TableRequest";
import {TableSort} from "../../../models/core/table/TableSort";
import {Vpn} from "../../../models/vpn/Vpn";
import TableFilterComponent from "../TableFilterComponent";
import TablePaginationComponent from "../TablePaginationComponent";
import VpnDataComponent from "./VpnDataComponent";


declare var canvasOpen: any;

export default function VpnComponent() {

    const [filters, changeFilters] = useState<TableFilter[]>([
        new TableFilter('status', 'Status', [], false, TableFilterType.Enum, '')
    ]);
    const [table, changeTable] = useState<Table>(
        Core.initializeTable([new TableSort('username', 1)])
    );

    const [vpn, changeVpn] = useState<Vpn>(Core.initializeVpn());

    const canvas = (event: any, id: string | undefined = undefined) => {

        initializeData(id);
        canvasOpen();

        event.preventDefault();

    }

    const initializeData = (id: string | undefined = undefined) => {

        fetch(process.env.REACT_APP_API_URL + '/vpn/data/', Rest.initializeRequest(
            new DataRequest(undefined, undefined, undefined, id),
            '/vpn/data/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== null) {

                    changeVpn(data.data);

                } else {

                    changeVpn(Core.initializeVpn());

                }

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    const initializeTable = () => {

        fetch(process.env.REACT_APP_API_URL + '/vpn/table/', Rest.initializeRequest(
            new TableRequest(undefined, undefined, undefined, filters, table.pagination!.limit!.value, table.offset, table.sorts),
            '/vpn/table/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== undefined) {

                    changeTable(Core.renderNewTable(table, data.data, data.total));

                }

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    useEffect(() => {

        initializeTable();

    }, []);

    return (
        <div className="content">
            <div className="table">
                <div className="table-button">
                    <TableFilterComponent filters={filters} changeFilters={changeFilters}
                                          initializeTable={initializeTable}></TableFilterComponent>
                    <div className="table-action">
                        <button>
                            <i className="ti ti-cloud-download mr-5"></i>Export
                        </button>
                        <button onClick={(event) => canvas(event)}>
                            <i className="ti ti-file-plus mr-5"></i>Add New
                        </button>
                    </div>
                </div>
                <div className="table-data">
                    <table className="table-full">
                        <thead>
                        <tr>
                            <th></th>
                            <th>User</th>
                            <th>Limit</th>
                            <th>Usage</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (table.rows.length === 0) && <tr>
                                <td className="text-center" colSpan={6}>No Data Available</td>
                            </tr>
                        }
                        {
                            (table.rows.length > 0) && table.rows.map((row: Vpn, index: number) => {

                                return (
                                    <tr key={index}>
                                        <td>{(table.offset! + (index + 1))}</td>
                                        <td>{row.user!.username}</td>
                                        <td>{row.quota!.limit}</td>
                                        <td>{row.quota!.usage}</td>
                                        <td>{Core.toStandardCase(row.status!)}</td>
                                        <td>
                                            <button onClick={(event) => canvas(event, row.id)}>
                                                <i className="ti ti-pencil-minus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                );

                            })
                        }
                        </tbody>
                    </table>
                </div>
                <TablePaginationComponent table={table} tableIndex={0} changeTable={changeTable}
                                          initializeTable={initializeTable}></TablePaginationComponent>
            </div>
            <VpnDataComponent vpn={vpn} initializeTable={initializeTable}></VpnDataComponent>
        </div>
    );

}
