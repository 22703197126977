import {BaseRequest} from "../core/BaseRequest";
import {Asset} from "./Asset";


export class AssetSaveRequest extends BaseRequest {

    public authentication: string | undefined;

    public data: Asset | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, data: Asset | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.data = data;

    }

}
