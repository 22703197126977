import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import NotFoundComponent from "./components/core/NotFoundComponent";
import AccountAddressComponent from "./components/pages/account/AccountAddressComponent";
import AccountContactComponent from "./components/pages/account/AccountContactComponent";
import AccountProfileComponent from "./components/pages/account/AccountProfileComponent";
import AccountSecurityComponent from "./components/pages/account/AccountSecurityComponent";
import AssetChartComponent from "./components/pages/asset/AssetChartComponent";
import AssetComponent from "./components/pages/asset/AssetComponent";
import AssetDetailComponent from "./components/pages/asset/AssetDetailComponent";
import AuthenticationComponent from "./components/pages/authentication/AuthenticationComponent";
import IndexComponent from "./components/pages/index/IndexComponent";
import LayoutComponent from "./components/pages/LayoutComponent";
import NawalaGroupComponent from "./components/pages/nawala/group/NawalaGroupComponent";
import NawalaReportComponent from "./components/pages/nawala/report/NawalaReportComponent";
import NawalaUrlComponent from "./components/pages/nawala/url/NawalaUrlComponent";
import UserComponent from "./components/pages/user/UserComponent";
import VpnAccountComponent from "./components/pages/vpn/account/VpnAccountComponent";
import VpnServerComponent from "./components/pages/vpn/server/VpnServerComponent";
import VpnComponent from "./components/pages/vpn/VpnComponent";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/" element={<LayoutComponent></LayoutComponent>}>
                <Route index element={<IndexComponent></IndexComponent>}></Route>
                <Route path="/account" element={<AccountProfileComponent></AccountProfileComponent>}></Route>
                <Route path="/account/address" element={<AccountAddressComponent></AccountAddressComponent>}></Route>
                <Route path="/account/contact" element={<AccountContactComponent></AccountContactComponent>}></Route>
                <Route path="/account/security" element={<AccountSecurityComponent></AccountSecurityComponent>}></Route>
                <Route path="/assets" element={<AssetComponent></AssetComponent>}></Route>
                <Route path="/assets/:market/:name" element={<AssetDetailComponent></AssetDetailComponent>}></Route>
                <Route path="/assets/:market/:name/chart" element={
                    <AssetChartComponent></AssetChartComponent>}></Route>
                <Route path="/nawala/groups" element={<NawalaGroupComponent></NawalaGroupComponent>}></Route>
                <Route path="/nawala/reports" element={<NawalaReportComponent></NawalaReportComponent>}></Route>
                <Route path="/nawala/urls" element={<NawalaUrlComponent></NawalaUrlComponent>}></Route>
                <Route path="/users" element={<UserComponent></UserComponent>}></Route>
                <Route path="/vpn" element={<VpnComponent></VpnComponent>}></Route>
                <Route path="/vpn/accounts" element={<VpnAccountComponent></VpnAccountComponent>}></Route>
                <Route path="/vpn/servers" element={<VpnServerComponent></VpnServerComponent>}></Route>
            </Route>
            <Route path="/authentication" element={<AuthenticationComponent></AuthenticationComponent>}></Route>
            <Route path="*" element={<NotFoundComponent></NotFoundComponent>}></Route>
        </Route>
    )
)


export default function App() {
    return (
        <RouterProvider router={router}></RouterProvider>
    );
}
