import {DataRange} from "../enums/data/DataRange";
import {Asset} from "../models/asset/Asset";
import {AssetMarket} from "../models/asset/AssetMarket";
import {AssetSec} from "../models/asset/AssetSec";
import {Table} from "../models/core/table/Table";
import {TableFilter} from "../models/core/table/TableFilter";
import {TablePagination} from "../models/core/table/TablePagination";
import {TablePaginationLimit} from "../models/core/table/TablePaginationLimit";
import {TablePaginationLimitOption} from "../models/core/table/TablePaginationLimitOption";
import {TableSort} from "../models/core/table/TableSort";
import {DataAsset} from "../models/data/asset/DataAsset";
import {DataAssetChange} from "../models/data/asset/DataAssetChange";
import {DataAssetPrice} from "../models/data/asset/DataAssetPrice";
import {NawalaGroup} from "../models/nawala/group/NawalaGroup";
import {NawalaGroupQuota} from "../models/nawala/group/NawalaGroupQuota";
import {NawalaGroupReference} from "../models/nawala/group/NawalaGroupReference";
import {NawalaUrl} from "../models/nawala/url/NawalaUrl";
import {User} from "../models/user/User";
import {UserAddress} from "../models/user/UserAddress";
import {UserName} from "../models/user/UserName";
import {UserPassword} from "../models/user/UserPassword";
import {UserReference} from "../models/user/UserReference";
import {VpnAccount} from "../models/vpn/account/VpnAccount";
import {VpnServer} from "../models/vpn/server/VpnServer";
import {Vpn} from "../models/vpn/Vpn";
import {VpnQuota} from "../models/vpn/VpnQuota";


export class Core {

    public static initializeAsset(): Asset {

        return new Asset(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            new AssetMarket(
                undefined,
                undefined
            ),
            undefined,
            new AssetSec(
                undefined
            ),
            undefined,
            undefined
        );

    }


    public static initializeAuthentication(): string | undefined {

        let result: string | undefined = undefined;

        if(localStorage.getItem('authentication') !== null) {

            result = localStorage.getItem('authentication')!;

        }

        return result;

    }


    public static initializeDataAsset(): DataAsset {

        return new DataAsset(
            undefined,
            undefined,
            undefined,
            new DataAssetChange(
                undefined,
                undefined
            ),
            undefined,
            new DataAssetPrice(
                undefined,
                undefined,
                undefined,
                undefined
            ),
            undefined,
            undefined
        );

    }


    public static initializeDataAssetDetailRange(range: DataRange): Date {

        let start: Date = new Date();

        if(range === DataRange.Max) {

            start.setFullYear(1900);

        } else if(range === DataRange.FiveYears) {

            start.setFullYear(start.getFullYear() - 5);

        } else if(range === DataRange.ThreeYears) {

            start.setFullYear(start.getFullYear() - 3);

        } else if(range === DataRange.OneYear) {

            start.setFullYear(start.getFullYear() - 1);

        } else if(range === DataRange.SixMonths || range === DataRange.ThreeMonths || range === DataRange.OneMonth) {

            let month: number = start.getMonth();

            if(range === DataRange.SixMonths) {

                month = month - 6;

            } else if(range === DataRange.ThreeMonths) {

                month = month - 3;

            } else if(range === DataRange.OneMonth) {

                month = month - 1;

            }

            start.setMonth(month);

        } else if(range === DataRange.OneWeek) {

            let date: number = start.getDate() - 7;
            start.setDate(date);

        }

        return start;

    }


    public static initializeNawalaGroup(): NawalaGroup {

        return new NawalaGroup(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            new NawalaGroupQuota(
                undefined,
                undefined
            ),
            undefined,
            undefined
        );

    }


    public static initializeNawalaUrl(): NawalaUrl {

        return new NawalaUrl(
            undefined,
            undefined,
            undefined,
            undefined,
            new NawalaGroupReference(
                undefined,
                undefined,
                undefined
            ),
            undefined,
            undefined,
            undefined
        );

    }


    public static initializeSwalDelete(text: string, title: string): any {

        return {
            confirmButtonText: 'Delete',
            showCancelButton: true,
            text: text,
            title: title
        };

    }


    public static initializeTable(sorts: TableSort[]): Table {

        return new Table(
            [],
            0,
            new TablePagination(
                1,
                new TablePaginationLimit(
                    [
                        new TablePaginationLimitOption('10', 10),
                        new TablePaginationLimitOption('50', 50),
                        new TablePaginationLimitOption('100', 100),
                        new TablePaginationLimitOption('500', 500),
                        new TablePaginationLimitOption('All', 0)
                    ],
                    50,
                    '80px'
                ),
                0
            ),
            [],
            sorts,
            0
        );

    }


    public static initializeUser(): User {

        return new User(
            undefined,
            undefined,
            undefined,
            new UserAddress(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
            ),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            new UserPassword(
                undefined,
                undefined
            ),
            undefined,
            undefined,
            undefined
        );

    }


    public static initializeUserName(userName: UserName): string {

        let name: string = '';

        if(userName !== undefined) {

            if(userName.first !== null) {

                name = userName.first!;

            }

            if(userName.middle !== null) {

                name += ' ' + userName.middle;

            }

            if(userName.last !== null) {

                name += ' ' + userName.last;

            }

        }

        return name;

    }


    public static initializeVpn(): Vpn {

        return new Vpn(
            undefined,
            undefined,
            undefined,
            new VpnQuota(
                undefined,
                undefined
            ),
            undefined,
            new UserReference(
                undefined,
                undefined
            )
        );

    }


    public static initializeVpnAccount(): VpnAccount {

        return new VpnAccount(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            new UserReference(
                undefined,
                undefined
            ),
            undefined
        );

    }


    public static initializeVpnServer(): VpnServer {

        return new VpnServer(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
        );

    }


    public static renderFilter(filters: TableFilter[]): TableFilter[] {

        let result: TableFilter[] = [];

        filters.map((filter: TableFilter, index: number) => {

            result.push(filter);

        });

        return result;

    }


    public static renderNewTable(table: Table, data: any | undefined = undefined, total: number | undefined = undefined): Table {

        if(data !== undefined) {

            table.rows = data;

        }

        if(total !== undefined) {

            table.total = total;

        }

        return new Table(
            table.columns,
            table.offset,
            table.pagination,
            table.rows,
            table.sorts,
            table.total
        );

    }


    public static toCapital(string: string): string {

        return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

    }


    public static toDatetime(date: Date): Date {

        return new Date(date + '+0000');

    }


    public static toNumberSeparator(number: number): string {

        return number.toLocaleString(undefined, {minimumFractionDigits: 2});

    }


    public static toStandardCase(text: string): string {

        let result: string = '';

        if(text !== undefined) {

            result = text.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1').replace(/([0-9]+)/g, ' $1').trim();

        }

        return result;

    }

}
