import {AssetDetailPredictionValue} from "./AssetDetailPredictionValue";


export class AssetDetailPrediction {

    public daily: AssetDetailPredictionValue | undefined;

    public fifteenMinutes: AssetDetailPredictionValue | undefined;

    public fiveMinutes: AssetDetailPredictionValue | undefined;

    public fourHours: AssetDetailPredictionValue | undefined;

    public hourly: AssetDetailPredictionValue | undefined;

    public monthly: AssetDetailPredictionValue | undefined;

    public thirtyMinutes: AssetDetailPredictionValue | undefined;

    public weekly: AssetDetailPredictionValue | undefined;


    constructor(daily: AssetDetailPredictionValue | undefined, fifteenMinutes: AssetDetailPredictionValue | undefined, fiveMinutes: AssetDetailPredictionValue | undefined, fourHours: AssetDetailPredictionValue | undefined, hourly: AssetDetailPredictionValue | undefined, monthly: AssetDetailPredictionValue | undefined, thirtyMinutes: AssetDetailPredictionValue | undefined, weekly: AssetDetailPredictionValue | undefined) {

        this.daily = daily;
        this.fifteenMinutes = fifteenMinutes;
        this.fiveMinutes = fiveMinutes;
        this.fourHours = fourHours;
        this.hourly = hourly;
        this.monthly = monthly;
        this.thirtyMinutes = thirtyMinutes;
        this.weekly = weekly;

    }

}
