import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {TableFilterType} from "../../../../enums/core/table/TableFilterType";
import {Core} from "../../../../libraries/Core";
import {Rest} from "../../../../libraries/Rest";
import {DataRequest} from "../../../../models/core/DataRequest";
import {Table} from "../../../../models/core/table/Table";
import {TableFilter} from "../../../../models/core/table/TableFilter";
import {TableRequest} from "../../../../models/core/table/TableRequest";
import {TableSort} from "../../../../models/core/table/TableSort";
import {VpnServer} from "../../../../models/vpn/server/VpnServer";
import TableFilterComponent from "../../TableFilterComponent";
import TablePaginationComponent from "../../TablePaginationComponent";
import VpnServerDataComponent from "./VpnServerDataComponent";


declare var canvasOpen: any;

export default function VpnServerComponent() {

    const [filters, changeFilters] = useState<TableFilter[]>([
        new TableFilter('status', 'Status', [], false, TableFilterType.Enum, '')
    ]);
    const [table, changeTable] = useState<Table>(
        Core.initializeTable([new TableSort('username', 1)])
    );

    const [vpnServer, changeVpnServer] = useState<VpnServer>(Core.initializeVpnServer());

    const canvas = (event: any, id: string | undefined = undefined) => {

        initializeData(id);
        canvasOpen();

        event.preventDefault();

    }

    const initializeData = (id: string | undefined = undefined) => {

        fetch(process.env.REACT_APP_API_URL + '/vpn/server/data/', Rest.initializeRequest(
            new DataRequest(undefined, undefined, Core.initializeAuthentication(), id),
            '/vpn/server/data/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== null) {

                    changeVpnServer(data.data);

                } else {

                    changeVpnServer(Core.initializeVpnServer());

                }

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    const initializeTable = () => {

        fetch(process.env.REACT_APP_API_URL + '/vpn/server/table/', Rest.initializeRequest(
            new TableRequest(undefined, undefined, undefined, filters, table.pagination!.limit!.value, table.offset, table.sorts),
            '/vpn/server/table/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== undefined) {

                    changeTable(Core.renderNewTable(table, data.data, data.total));

                }

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    const remove = (event: any, id: string, name: string) => {

        Swal.fire(Core.initializeSwalDelete('Are you sure want to delete?', name)).then((result) => {

            if(result.isConfirmed) {

                fetch(process.env.REACT_APP_API_URL + '/vpn/server/delete/', Rest.initializeRequest(
                    new DataRequest(undefined, undefined, Core.initializeAuthentication(), id),
                    '/vpn/server/delete/'
                )).then(response => response.json()).then(data => {

                    if(data.result) {

                        toast.success(data.response);

                        initializeTable();

                    } else {

                        toast.error(data.response);

                    }

                }).catch(error => {

                    toast.error(error);

                });

            }

        });

        event.preventDefault();

    }

    useEffect(() => {

        initializeTable();

    }, []);

    return (
        <div className="content">
            <div className="table">
                <div className="table-button">
                    <TableFilterComponent filters={filters} changeFilters={changeFilters}
                                          initializeTable={initializeTable}></TableFilterComponent>
                    <div className="table-action">
                        <button>
                            <i className="ti ti-cloud-download mr-5"></i>Export
                        </button>
                        <button onClick={(event) => canvas(event)}>
                            <i className="ti ti-file-plus mr-5"></i>Add New
                        </button>
                    </div>
                </div>
                <div className="table-data">
                    <table className="table-full">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Hostname</th>
                            <th>Identity</th>
                            <th>IP</th>
                            <th>Location</th>
                            <th>Provider</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (table.rows.length === 0) && <tr>
                                <td className="text-center" colSpan={9}>No Data Available</td>
                            </tr>
                        }
                        {
                            (table.rows.length > 0) && table.rows.map((row: VpnServer, index: number) => {

                                return (
                                    <tr key={index}>
                                        <td>{(table.offset! + (index + 1))}</td>
                                        <td>{row.name}</td>
                                        <td>{row.hostname}</td>
                                        <td>{row.identity}</td>
                                        <td>{row.ip}</td>
                                        <td>{Core.toStandardCase(row.location!)}</td>
                                        <td>{Core.toStandardCase(row.provider!)}</td>
                                        <td>{Core.toStandardCase(row.status!)}</td>
                                        <td>
                                            <button onClick={(event) => canvas(event, row.id)}>
                                                <i className="ti ti-pencil-minus"></i>
                                            </button>
                                            <button onClick={(event) => remove(event, row.id!, row.name!)}>
                                                <i className="ti ti-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                );

                            })
                        }
                        </tbody>
                    </table>
                </div>
                <TablePaginationComponent table={table} tableIndex={0} changeTable={changeTable}
                                          initializeTable={initializeTable}></TablePaginationComponent>
            </div>
            <VpnServerDataComponent vpnServer={vpnServer} initializeTable={initializeTable}></VpnServerDataComponent>
        </div>
    );

}
