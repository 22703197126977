export enum AssetExchange {

    ARCA = 'ARCA',
    BOX = 'BOX',
    EUREX = 'EUREX',
    FUNDSERV = 'FUNDSERV',
    FWB = 'FWB',
    IDEALPRO = 'IDEALPRO',
    MEFFRV = 'MEFFRV',
    PAXOS = 'PAXOS',
    SBF = 'SBF',
    SMART = 'SMART'

}
