import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {Status} from "../../../../enums/core/Status";
import {TableFilterType} from "../../../../enums/core/table/TableFilterType";
import {Core} from "../../../../libraries/Core";
import {Rest} from "../../../../libraries/Rest";
import {DataRequest} from "../../../../models/core/DataRequest";
import {Table} from "../../../../models/core/table/Table";
import {TableFilter} from "../../../../models/core/table/TableFilter";
import {TableRequest} from "../../../../models/core/table/TableRequest";
import {TableSort} from "../../../../models/core/table/TableSort";
import {NawalaGroup} from "../../../../models/nawala/group/NawalaGroup";
import {NawalaGroupOption} from "../../../../models/nawala/group/NawalaGroupOption";
import TableFilterComponent from "../../TableFilterComponent";
import TablePaginationComponent from "../../TablePaginationComponent";
import NawalaGroupDataComponent from "./NawalaGroupDataComponent";


declare var canvasOpen: any;

export default function NawalaGroupComponent() {

    const [filters, changeFilters] = useState<TableFilter[]>([
        new TableFilter('name', 'Name', [], false, TableFilterType.String, ''),
        new TableFilter('key', 'Key', [], false, TableFilterType.String, ''),
        new TableFilter('status', 'Status', [Status.Active, Status.Inactive], false, TableFilterType.Enum, '')
    ]);
    const [table, changeTable] = useState<Table>(
        Core.initializeTable([new TableSort('name', 1)])
    );

    const [nawalaGroup, changeNawalaGroup] = useState<NawalaGroup>(Core.initializeNawalaGroup());
    const [option, changeOption] = useState<NawalaGroupOption>(new NawalaGroupOption(
        []
    ));

    const canvas = (event: any, id: string | undefined = undefined) => {

        initializeData(id);
        canvasOpen();

        event.preventDefault();

    }

    const initializeData = (id: string | undefined = undefined) => {

        fetch(process.env.REACT_APP_API_URL + '/nawala/group/data/', Rest.initializeRequest(
            new DataRequest(undefined, undefined, undefined, id),
            '/nawala/group/data/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== null) {

                    changeNawalaGroup(data.data);

                } else {

                    changeNawalaGroup(Core.initializeNawalaGroup());

                }

                changeOption(new NawalaGroupOption(
                    data.users
                ));

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    const initializeTable = () => {

        fetch(process.env.REACT_APP_API_URL + '/nawala/group/table/', Rest.initializeRequest(
            new TableRequest(undefined, undefined, undefined, filters, table.pagination!.limit!.value, table.offset, table.sorts),
            '/nawala/group/table/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.data !== undefined) {

                    changeTable(Core.renderNewTable(table, data.data, data.total));

                }

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

    }

    const messageDevice = (event: any, id: string) => {

        fetch(process.env.REACT_APP_API_URL + '/nawala/group/test-message-device/', Rest.initializeRequest(
            new DataRequest(undefined, undefined, Core.initializeAuthentication(), id),
            '/nawala/group/test-message-device/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                toast.success(data.response);

                initializeTable();

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    const messageUrl = (event: any, id: string) => {

        fetch(process.env.REACT_APP_API_URL + '/nawala/group/test-message-url/', Rest.initializeRequest(
            new DataRequest(undefined, undefined, Core.initializeAuthentication(), id),
            '/nawala/group/test-message-url/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                toast.success(data.response);

                initializeTable();

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    const remove = (event: any, id: string, name: string) => {

        Swal.fire(Core.initializeSwalDelete('Are you sure want to delete?', name)).then((result) => {

            if(result.isConfirmed) {

                fetch(process.env.REACT_APP_API_URL + '/nawala/group/delete/', Rest.initializeRequest(
                    new DataRequest(undefined, undefined, Core.initializeAuthentication(), id),
                    '/nawala/group/delete/'
                )).then(response => response.json()).then(data => {

                    if(data.result) {

                        toast.success(data.response);

                        initializeTable();

                    } else {

                        toast.error(data.response);

                    }

                }).catch(error => {

                    toast.error(error);

                });

            }

        });

        event.preventDefault();

    }

    useEffect(() => {

        initializeTable();

    }, []);

    return (
        <div className="content">
            <div className="table">
                <div className="table-button">
                    <TableFilterComponent filters={filters} changeFilters={changeFilters} initializeTable={initializeTable}></TableFilterComponent>
                    <div className="table-action">
                        <button>
                            <i className="ti ti-cloud-download mr-5"></i>Export
                        </button>
                        <button onClick={(event) => canvas(event)}>
                            <i className="ti ti-file-plus mr-5"></i>Add New
                        </button>
                    </div>
                </div>
                <div className="table-data">
                    <table className="table-full">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Key</th>
                            <th>Interval</th>
                            <th>Quota</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (table.rows.length === 0) && <tr>
                                <td className="text-center" colSpan={7}>No Data Available</td>
                            </tr>
                        }
                        {
                            (table.rows.length > 0) && table.rows.map((row: NawalaGroup, index: number) => {

                                return (
                                    <tr key={index}>
                                        <td>{(table.offset! + (index + 1))}</td>
                                        <td>{row.name}</td>
                                        <td>{row.key}</td>
                                        <td>{row.interval}</td>
                                        <td>{row.quota!.limit}</td>
                                        <td>{Core.toStandardCase(row.status!)}</td>
                                        <td>
                                            <button onClick={(event) => canvas(event, row.id)}>
                                                <i className="ti ti-pencil-minus"></i>
                                            </button>
                                            <button onClick={(event) => remove(event, row.id!, row.name!)}>
                                                <i className="ti ti-trash"></i>
                                            </button>
                                            <button onClick={(event) => messageUrl(event, row.id!)}>
                                                <i className="ti ti-brand-telegram"></i>
                                            </button>
                                            <button onClick={(event) => messageDevice(event, row.id!)}>
                                                <i className="ti ti-brand-discord"></i>
                                            </button>
                                        </td>
                                    </tr>
                                );

                            })
                        }
                        </tbody>
                    </table>
                </div>
                <TablePaginationComponent table={table} tableIndex={0} changeTable={changeTable} initializeTable={initializeTable}></TablePaginationComponent>
            </div>
            <NawalaGroupDataComponent nawalaGroup={nawalaGroup} option={option} initializeTable={initializeTable}></NawalaGroupDataComponent>
        </div>
    );

}
