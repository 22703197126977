import {AssetMarketType} from "../../enums/asset/AssetMarketType";
import {DataTimeframe} from "../../enums/data/DataTimeframe";
import {DataType} from "../../enums/data/DataType";
import {BaseRequest} from "../core/BaseRequest";


export class AssetChartRequest extends BaseRequest {

    public end: Date | undefined;

    public market: AssetMarketType | undefined;

    public name: string | undefined;

    public start: Date | undefined;

    public timeframe: DataTimeframe | undefined;

    public type: DataType | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, end: Date | undefined, market: AssetMarketType | undefined, name: string | undefined, start: Date | undefined, timeframe: DataTimeframe | undefined, type: DataType | undefined) {

        super(timestamp, token);
        this.end = end;
        this.market = market;
        this.name = name;
        this.start = start;
        this.timeframe = timeframe;
        this.type = type;

    }

}
