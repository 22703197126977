import React, {Fragment, useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {Link, NavigateFunction, Outlet, useLocation, useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {Platform} from "../../enums/core/Platform";
import {UserLogType} from "../../enums/user/log/UserLogType";
import {UserType} from "../../enums/user/UserType";
import {Core} from "../../libraries/Core";
import {Rest} from "../../libraries/Rest";
import {InitializeAccountRequest} from "../../models/user/log/InitializeAccountRequest";
import {LogoutRequest} from "../../models/user/log/LogoutRequest";
import {User} from "../../models/user/User";
import {Vpn} from "../../models/vpn/Vpn";


declare var hidePreloader: any;
declare var layout: any;

export default function LayoutComponent() {

    const location: any = useLocation();
    const navigate: NavigateFunction = useNavigate();

    const [account, changeAccount] = useState<User>(Core.initializeUser());
    const [vpn, changeVpn] = useState<Vpn>(Core.initializeVpn());

    const initializeAccount = (authentication: string | undefined = undefined) => {

        fetch(process.env.REACT_APP_API_URL + '/user/log/initialize-account/', Rest.initializeRequest(
            new InitializeAccountRequest(undefined, undefined, authentication),
            '/user/log/initialize-account/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                if(data.log.type === UserLogType.Login) {

                    localStorage.setItem('version', data.user.layout.version.toString());
                    localStorage.setItem('aside', data.user.layout.sidebar?.toString());

                    changeAccount(data.user);
                    changeVpn(data.vpn);

                    if(data.log.type === UserLogType.Login && !data.log.remember) {

                        const dayDifference: number = (new Date().getTime() - new Date(data.log.created.timestamp).getTime()) / (1000 * 60 * 60 * 24);

                        if(dayDifference > 1) {

                            localStorage.clear();

                            navigate('/authentication/');

                        }

                    }

                } else {

                    localStorage.clear();

                    navigate('/authentication/');

                }

            } else {

                localStorage.clear();

                navigate('/authentication/');

            }

        });

    }

    const logout = (event: any) => {

        fetch(process.env.REACT_APP_API_URL + '/user/log/logout/', Rest.initializeRequest(
            new LogoutRequest(undefined, undefined, Core.initializeAuthentication(), Platform.Desktop),
            '/user/log/logout/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                localStorage.removeItem('authentication');

                navigate('/authentication/');

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect((): any => {

        document.body.classList.add('overflow-hidden');

        layout();
        hidePreloader();

        let authentication: string | undefined = Core.initializeAuthentication();

        if(authentication === undefined) {

            navigate('/authentication/');

        } else {

            initializeAccount(authentication);

        }

    }, [location]);

    return (
        <div id="wrapper">
            <ToastContainer position="top-right" autoClose={5000} closeOnClick newestOnTop={true} pauseOnHover={false} theme="dark"></ToastContainer>
            <div id="preloader">
                <div className="cube">
                    <div className="load-cube c1"></div>
                    <div className="load-cube c2"></div>
                    <div className="load-cube c4"></div>
                    <div className="load-cube c3"></div>
                </div>
            </div>
            <aside>
                <div className="aside-wrapper">
                    <div className="aside-logo">
                        <h1>
                            <Link to={'/'}>
                                <img src="/images/logo/logo.png" alt={process.env.REACT_APP_COMPANY_NAME}/>
                            </Link>
                        </h1>
                        <h2>
                            <Link to={'/'}>{process.env.REACT_APP_APPLICATION_NAME}</Link>
                        </h2>
                        <div className="aside-toggle">
                            <i className="ti ti-chevrons-left"></i>
                        </div>
                    </div>
                    <div className="aside-menu">
                        <PerfectScrollbar>
                            <ul>
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/'}>
                                            <i className="ti ti-layout-dashboard mr-5"></i>
                                            <span>Dashboard</span>
                                        </Link>
                                        <div className="aside-menu-toggle">
                                            <i className="ti ti-chevron-right"></i>
                                        </div>
                                    </div>
                                </li>
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <Fragment>
                                        <li className="aside-menu-title">
                                            <p><i className="ti ti-coin mr-5"></i>Asset</p>
                                        </li>
                                        <li>
                                            <div className="aside-menu-item">
                                                <Link to={'/assets/'}>
                                                    <i className="ti ti-coin mr-5"></i>
                                                    <span>Asset</span>
                                                </Link>
                                            </div>
                                        </li>
                                    </Fragment>
                                }
                                <li className="aside-menu-title">
                                    <p><i className="ti ti-user-check mr-5"></i>Account</p>
                                </li>
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/account/'}>
                                            <i className="ti ti-user-scan mr-5"></i>
                                            <span>Profile</span>
                                        </Link>
                                    </div>
                                </li>
                                <li className="aside-menu-title">
                                    <p><i className="ti ti-lock-access-off mr-5"></i>Nawala</p>
                                </li>
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <li>
                                        <div className="aside-menu-item">
                                            <Link to={'/nawala/groups/'}>
                                                <i className="ti ti-buildings mr-5"></i>
                                                <span>Group</span>
                                            </Link>
                                        </div>
                                    </li>
                                }
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/nawala/urls/'}>
                                            <i className="ti ti-world-www mr-5"></i>
                                            <span>URL</span>
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/nawala/reports/'}>
                                            <i className="ti ti-report mr-5"></i>
                                            <span>Report</span>
                                        </Link>
                                    </div>
                                </li>
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <li className="aside-menu-title">
                                        <p><i className="ti ti-users mr-5"></i>Accounts</p>
                                    </li>
                                }
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <li>
                                        <div className="aside-menu-item">
                                            <Link to={'/users/'}>
                                                <i className="ti ti-user-shield mr-5"></i>
                                                <span>User</span>
                                            </Link>
                                        </div>
                                    </li>
                                }
                                <li className="aside-menu-title">
                                    <p><i className="ti ti-brand-openvpn mr-5"></i>VPN</p>
                                </li>
                                {
                                    (account.type === UserType.Owner || account.type === UserType.Administrator) &&
                                    <Fragment>
                                        <li>
                                            <div className="aside-menu-item">
                                                <Link to={'/vpn/'}>
                                                    <i className="ti ti-brand-openvpn mr-5"></i>
                                                    <span>VPN</span>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="aside-menu-item">
                                                <Link to={'/vpn/servers/'}>
                                                    <i className="ti ti-server mr-5"></i>
                                                    <span>Server</span>
                                                </Link>
                                            </div>
                                        </li>
                                    </Fragment>
                                }
                                <li>
                                    <div className="aside-menu-item">
                                        <Link to={'/vpn/accounts/'}>
                                            <i className="ti ti-user-circle mr-5"></i>
                                            <span>Account</span>
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </PerfectScrollbar>
                    </div>
                </div>
            </aside>
            <div id="content">
                <header>
                    <div className="header-wrapper">
                        <div className="header-menu">
                            <i className="ti ti-menu-2 header-menu-toggle"></i>
                        </div>
                        <div className="header-search">
                            <input name="search" placeholder="Search..."/>
                            <div className="header-search-icon">
                                <i className="ti ti-search"></i>
                            </div>
                        </div>
                        <div className="header-account-wrapper">
                            <div className="header-account">
                                <div className="header-account-name">
                                    <p>{Core.initializeUserName(account.name!)}</p>
                                    <p>{Core.toStandardCase(account.type!)}</p>
                                </div>
                                <div className="header-account-avatar">
                                    <img src="/images/avatar/avatar-male.png" alt={process.env.REACT_APP_COMPANY_NAME + ' Avatar'}/>
                                    <div className="header-account-avatar-status"></div>
                                </div>
                            </div>
                            <div className="header-account-popup">
                                <div className="header-account-popup-account">
                                    <div className="header-account-popup-avatar">
                                        <img src="/images/avatar/avatar-male.png" alt={process.env.REACT_APP_COMPANY_NAME + ' Avatar'}/>
                                        <div className="header-account-avatar-status"></div>
                                    </div>
                                    <div className="header-account-popup-name">
                                        <p>{Core.initializeUserName(account.name!)}</p>
                                        <p>{Core.toStandardCase(account.type!)}</p>
                                    </div>
                                </div>
                                <div className="header-account-popup-row">
                                    <button className="logout" onClick={(event) => logout(event)}>
                                        <i className="ti ti-logout mr-5"></i>Logout
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <PerfectScrollbar>
                    <Outlet context={{account, vpn}}></Outlet>
                </PerfectScrollbar>
                <footer>
                    <div className="footer-wrapper">
                        Copyright © {new Date().getFullYear()}&nbsp;
                        <Link to={'/'}>{process.env.REACT_APP_COMPANY_NAME}</Link>.&nbsp;
                        All rights reserved.
                    </div>
                </footer>
            </div>
        </div>
    );

}
