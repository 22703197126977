import {AssetSecType} from "../../enums/asset/AssetSecType";


export class AssetSec {

    public type: AssetSecType | undefined;


    constructor(type: AssetSecType | undefined) {

        this.type = type;

    }

}
