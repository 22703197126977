import {DataTimeframe} from "../../../enums/data/DataTimeframe";
import {Base} from "../../core/Base";
import {Timestamp} from "../../core/Timestamp";
import {DataAssetChange} from "./DataAssetChange";
import {DataAssetPrice} from "./DataAssetPrice";


export class DataAsset extends Base {

    public change: DataAssetChange | undefined;

    public date: Date | undefined;

    public price: DataAssetPrice | undefined;

    public timeframe: DataTimeframe | undefined;

    public volume: number | undefined;


    constructor(id: string | undefined, created: Timestamp | undefined, modified: Timestamp | undefined, change: DataAssetChange | undefined, date: Date | undefined, price: DataAssetPrice | undefined, timeframe: DataTimeframe | undefined, volume: number | undefined) {

        super(id, created, modified);
        this.change = change;
        this.date = date;
        this.price = price;
        this.timeframe = timeframe;
        this.volume = volume;

    }

}
