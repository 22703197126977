import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {Core} from "../../libraries/Core";


// @ts-ignore
export default function TablePaginationComponent({table, tableIndex, changeTable, initializeTable}) {

    let last: number = table.total;

    if((table.offset * table.pagination.limit.value) + table.pagination.limit.value < last) {

        last = (table.offset * table.pagination.limit.value) + table.pagination.limit.value;

    }

    table.pagination.total = parseInt((table.total / table.pagination.limit.value).toString());

    if(table.pagination.limit.value > 1) {

        table.pagination.total += 1;

    }

    const changeLimit = (event: any) => {

        table.pagination.limit.value = parseInt(event.target.value);
        changeTable(Core.renderNewTable(table));

        initializeTable();

        event.preventDefault();

    }

    const changePage = (event: any, index: number, page: number) => {

        table.pagination!.current = page;
        table.offset! = (page - 1) * table.pagination!.limit!.value!;
        changeTable(Core.renderNewTable(table));

        initializeTable();

        event.preventDefault();

    }

    return (
        <div className="table-pagination">
            <div className="table-pagination-wrapper">
                <div className="column table-pagination-total">
                    Showing {((table.offset * table.pagination.limit.value) + 1) + ' to ' + last + ' of ' + table.total + ' items'}
                </div>
                <ul className="column table-pagination-page">
                    <li className={(table.pagination.current > 1 ? '' : 'disabled')} onClick={(event) => changePage(event, tableIndex, 1)}>
                        <i className="ti ti-chevron-left-pipe"></i>
                    </li>
                    {/*<li className={(table.pagination.current > 1 ? '' : ' disabled')} onClick={(event) => setTablePage(event, tableIndex, (table.pagination.current - 1))}>
                        <i className="ti ti-chevron-left-pipe"></i>
                    </li>*/}
                    {
                        table.pagination.total <= 6 && [...Array(table.pagination.total)].map((_, index: number) => {

                            return (
                                <li key={index} className={(index + 1) === table.pagination.current ? 'active' : ''} onClick={(event) => changePage(event, tableIndex, (index + 1))}>
                                    {index + 1}
                                </li>
                            );

                        })

                    }
                    {
                        table.pagination.total > 6 && [...Array(table.pagination.total)].map((_, index: number) => {

                            if(table.pagination.current < 5 && table.pagination.total > 6) {

                                if(index < 5) {

                                    return (
                                        <li key={index} className={(index + 1) === table.pagination.current ? 'active' : ''} onClick={(event) => changePage(event, tableIndex, (index + 1))}>
                                            {index + 1}
                                        </li>
                                    );

                                } else if((index + 1) === table.pagination.total) {

                                    return (
                                        <Fragment key={index}>
                                            <li className="disabled" onClick={(event) => event.preventDefault()}>
                                                …
                                            </li>
                                            <li key={index} className={(index + 1) === table.pagination.current ? 'active' : ''} onClick={(event) => changePage(event, tableIndex, (index + 1))}>
                                                {index + 1}
                                            </li>
                                        </Fragment>
                                    );

                                }

                            } else if(table.pagination.current >= 5 && (table.pagination.total - table.pagination.current) <= 3) {

                                if(index === 0) {

                                    return (
                                        <Fragment key={index}>
                                            <li key={index} className={(index + 1) === table.pagination.current ? 'active' : ''} onClick={(event) => changePage(event, tableIndex, (index + 1))}>
                                                {index + 1}
                                            </li>
                                            <li className="disabled" onClick={(event) => event.preventDefault()}>
                                                …
                                            </li>
                                        </Fragment>
                                    );

                                } else if((table.pagination.total - index) < 6) {

                                    return (
                                        <li key={index} className={(index + 1) === table.pagination.current ? 'active' : ''} onClick={(event) => changePage(event, tableIndex, (index + 1))}>
                                            {index + 1}
                                        </li>
                                    );

                                }

                            } else if(table.pagination.current >= 5 && (table.pagination.total - table.pagination.current) > 3) {

                                if(index === 0) {

                                    return (
                                        <Fragment key={index}>
                                            <li key={index} className={(index + 1) === table.pagination.current ? 'active' : ''} onClick={(event) => changePage(event, tableIndex, (index + 1))}>
                                                {index + 1}
                                            </li>
                                            <li className="disabled" onClick={(event) => event.preventDefault()}>
                                                …
                                            </li>
                                        </Fragment>
                                    );

                                } else if(index > (table.pagination.current - 3) && index < (table.pagination.current + 1)) {

                                    return (
                                        <li key={index} className={(index + 1) === table.pagination.current ? 'active' : ''} onClick={(event) => changePage(event, tableIndex, (index + 1))}>
                                            {index + 1}
                                        </li>
                                    );

                                } else if((index + 1) === table.pagination.total) {

                                    return (
                                        <Fragment key={index}>
                                            <li className="paginate_button page-item disabled">
                                                <Link className="page-link" to={'#'} onClick={(event) => event.preventDefault()}>…</Link>
                                            </li>
                                            <li key={index} className={(index + 1) === table.pagination.current ? 'active' : ''} onClick={(event) => changePage(event, tableIndex, (index + 1))}>
                                                {index + 1}
                                            </li>
                                        </Fragment>
                                    );

                                }

                            }

                        })
                    }
                    {/*<li className={'paginate_button page-item next' + (table.pagination.current < table.pagination.total ? '' : ' disabled')} onClick={(event) => setTablePage(event, tableIndex, (table.pagination.current + 1))}>
                        <i className="ti ti-chevron-right-pipe"></i>
                    </li>*/}
                    <li className={'paginate_button page-item next' + (table.pagination.current < table.pagination.total ? '' : ' disabled')} onClick={(event) => changePage(event, tableIndex, table.pagination.total)}>
                        <i className="ti ti-chevron-right-pipe"></i>
                    </li>
                </ul>
                <div className="column table-pagination-item">
                    <FormControl fullWidth>
                        <InputLabel id="pagination-item">Per Page</InputLabel>
                        <Select labelId="pagination-item" label="Per Page" value={table.pagination.limit.value} onChange={(event) => changeLimit(event)}>
                            {
                                table.pagination.limit.options.map((item: any, index: number) => {

                                    return (
                                        <MenuItem key={index} value={item.value}>{Core.toStandardCase(item.text)}</MenuItem>
                                    );

                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );

}
