export enum DataTimeframe {

    Annually = 'Annually',
    Daily = 'Daily',
    FifteenMinutes = 'FifteenMinutes',
    FiveMinutes = 'FiveMinutes',
    FourHours = 'FourHours',
    Hourly = 'Hourly',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    SemiAnnually = 'SemiAnnually',
    ThirtyMinutes = 'ThirtyMinutes',
    Weekly = 'Weekly'

}
