import {Status} from "../../enums/core/Status";
import {Base} from "../core/Base";
import {Timestamp} from "../core/Timestamp";
import {UserReference} from "../user/UserReference";
import {VpnQuota} from "./VpnQuota";


export class Vpn extends Base {

    public quota: VpnQuota | undefined;

    public status: Status | undefined;

    public user: UserReference | undefined;


    constructor(id: string | undefined, created: Timestamp | undefined, modified: Timestamp | undefined, quota: VpnQuota | undefined, status: Status | undefined, user: UserReference | undefined) {

        super(id, created, modified);
        this.quota = quota;
        this.status = status;
        this.user = user;

    }

}
