import {AssetMarketType} from "../../enums/asset/AssetMarketType";


export class AssetMarket {

    public cap: number | undefined;

    public type: AssetMarketType | undefined;


    constructor(cap: number | undefined, type: AssetMarketType | undefined) {

        this.cap = cap;
        this.type = type;

    }

}
