export enum AssetSecType {

    BOND = 'BOND',
    CASH = 'CASH',
    CFD = 'CFD',
    CMDTY = 'CMDTY',
    CONTFUT = 'CONTFUT',
    CRYPTO = 'CRYPTO',
    FOP = 'FOP',
    FUND = 'FUND',
    FUT = 'FUT',
    FUTCONTFUT = 'FUTCONTFUT',
    IND = 'IND',
    IOPT = 'IOPT',
    OPT = 'OPT',
    STK = 'STK',
    WAR = 'WAR'

}
