import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Status} from "../../../enums/core/Status";
import {Core} from "../../../libraries/Core";
import {Rest} from "../../../libraries/Rest";
import {UserReference} from "../../../models/user/UserReference";
import {Vpn} from "../../../models/vpn/Vpn";
import {VpnQuota} from "../../../models/vpn/VpnQuota";
import {VpnSaveRequest} from "../../../models/vpn/VpnSaveRequest";


declare var canvasClose: any;
declare var layoutCanvas: any;

// @ts-ignore
export default function VpnDataComponent({vpn, initializeTable}) {

    layoutCanvas();

    const [quotaLimit, changeQuotaLimit] = useState<number>(0);
    const [status, changeStatus] = useState<string>('');
    const [user, changeUser] = useState<UserReference | null>(null);

    const close = (event: any) => {

        canvasClose();

        event.preventDefault();

    }

    const save = (event: any): void => {

        fetch(process.env.REACT_APP_API_URL + '/vpn/save/', Rest.initializeRequest(
            new VpnSaveRequest(
                undefined,
                undefined,
                Core.initializeAuthentication(),
                new Vpn(
                    vpn.id,
                    undefined,
                    undefined,
                    new VpnQuota(
                        quotaLimit,
                        undefined
                    ),
                    Status[status as keyof typeof Status],
                    undefined
                )
            ),
            '/vpn/save/'
        )).then(response => response.json()).then(data => {

            if(data.result) {

                initializeTable();

                toast.success(data.response);

            } else {

                toast.error(data.response);

            }

        }).catch(error => {

            toast.error(error);

        });

        event.preventDefault();

    }

    useEffect(() => {

        if(vpn.id !== undefined) {

            changeQuotaLimit(vpn.quota.limit);
            changeStatus(vpn.status.toString());
            changeUser(vpn.user);

        } else {

            changeQuotaLimit(0);
            changeStatus('');
            changeUser(null);

        }

    }, [vpn]);

    return (
        <div className="canvas-wrapper">
            <div className="canvas-data">
                <div className="canvas-data-title">
                    <h5><i className="ti ti-world-www mr-5"></i>VPN</h5>
                    <button onClick={(event) => close(event)}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="canvas-data-form">
                    <div className="canvas-data-section">
                        <i className="ti ti-creative-commons-nd mr-5"></i>General
                    </div>
                    {
                        user !== null &&
                        <div className="canvas-data-field">
                            <TextField label="User" type="text" value={user!.username} disabled={true} variant="outlined"/>
                        </div>
                    }
                    <div className="canvas-data-field">
                        <TextField label="Quota" type="number" value={quotaLimit} onChange={(event) => changeQuotaLimit(parseInt(event.target.value))} variant="outlined"/>
                    </div>
                    <div className="canvas-data-field">
                        <FormControl fullWidth>
                            <InputLabel id="nawala-url-status">Status</InputLabel>
                            <Select labelId="nawala-url-status" label="Status" value={status} onChange={(event) => changeStatus(event.target.value)}>
                                {
                                    Object.keys(Status).map((item: any, index: number) => {

                                        return (
                                            <MenuItem key={index} value={item}>{Core.toStandardCase(item)}</MenuItem>
                                        );

                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="canvas-data-action">
                    <button onClick={(event) => save(event)}>
                        <i className="ti ti-cloud-upload mr-5"></i>Save
                    </button>
                </div>
            </div>
        </div>
    );

}
