export class DataAssetPrice {

    public close: number | undefined;

    public high: number | undefined;

    public low: number | undefined;

    public open: number | undefined;


    constructor(close: number | undefined, high: number | undefined, low: number | undefined, open: number | undefined) {

        this.close = close;
        this.high = high;
        this.low = low;
        this.open = open;

    }

}
