import {DataTimeframe} from "../../enums/data/DataTimeframe";
import {BaseRequest} from "../core/BaseRequest";


export class AssetLearnRequest extends BaseRequest {

    public authentication: string | undefined;

    public id: string | undefined;

    public timeframe: DataTimeframe | undefined;


    constructor(timestamp: string | undefined, token: string | undefined, authentication: string | undefined, id: string | undefined, timeframe: DataTimeframe | undefined) {

        super(timestamp, token);
        this.authentication = authentication;
        this.id = id;
        this.timeframe = timeframe;

    }

}
