export class DataAssetChange {

    public amount: number | undefined;

    public percentage: number | undefined;


    constructor(amount: number | undefined, percentage: number | undefined) {

        this.amount = amount;
        this.percentage = percentage;

    }

}
