import {AssetCurrency} from "../../enums/asset/AssetCurrency";
import {AssetExchange} from "../../enums/asset/AssetExchange";
import {Status} from "../../enums/core/Status";
import {Base} from "../core/Base";
import {Timestamp} from "../core/Timestamp";
import {AssetData} from "./AssetData";
import {AssetMarket} from "./AssetMarket";
import {AssetSec} from "./AssetSec";


export class Asset extends Base {

    public currency: AssetCurrency | undefined;

    public data: AssetData[] | undefined;

    public exchange: AssetExchange | undefined;

    public market: AssetMarket | undefined;

    public name: string | undefined;

    public sec: AssetSec | undefined;

    public status: Status | undefined;

    public symbol: string | undefined;


    constructor(id: string | undefined, created: Timestamp | undefined, modified: Timestamp | undefined, currency: AssetCurrency | undefined, data: AssetData[] | undefined, exchange: AssetExchange | undefined, market: AssetMarket | undefined, name: string | undefined, sec: AssetSec | undefined, status: Status | undefined, symbol: string | undefined) {

        super(id, created, modified);
        this.currency = currency;
        this.data = data;
        this.exchange = exchange;
        this.market = market;
        this.name = name;
        this.sec = sec;
        this.status = status;
        this.symbol = symbol;

    }

}
